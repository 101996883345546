/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";

import useAuth from "../context/auth";

const AuthRoute = ({ component, ...props }) => {
  const { isAuthenticated } = useAuth();
  const Component = component;

  return (
    <Route
      {...props}
      render={(renderProps) =>
        isAuthenticated ? (
          <Component {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referer: renderProps.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
