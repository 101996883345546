/** @jsx jsx */

import { jsx } from "@emotion/core";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Radio } from "../../Form";
import { H1 } from "../shared";

const UseTypeStep = ({ next, setValue, values }) => {
  const [isValid, setIsValid] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setIsValid(values.type !== null);
  }, [values.type]);

  return (
    <>
      <H1>
        <FormattedMessage
          id="onboarding.usage.title"
          defaultMessage="Comment allez-vous utiliser Gaston ?"
        />
      </H1>
      <div
        css={{ marginBottom: "2rem" }}
        onChange={(e) => setValue("type", e.target.value)}
      >
        <Radio
          id="personal"
          name="type"
          title={intl.formatMessage({
            defaultMessage: "À titre personnel",
            id: "onboarding.usage.choice.personal",
          })}
          value="personal"
        />
        <Radio
          id="professional"
          name="type"
          title={intl.formatMessage({
            defaultMessage: "À titre professionnel",
            id: "onboarding.usage.choice.professional",
          })}
          value="professional"
        />
      </div>
      <Button disabled={!isValid} onClick={next} type="submit">
        <FormattedMessage
          id="onboarding.usage.CTAcontinue"
          defaultMessage="Continuer"
        />
      </Button>
    </>
  );
};

export default UseTypeStep;
