/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-prototype-builtins */
/** @jsx jsx */

import { jsx } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "color";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { defineMessage, FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";

import API from "../API";
import { buttonStyle } from "../styles";

import { Button as FormButton } from "./Form";

const services = {
  aircall: {
    description: defineMessage({
      defaultMessage:
        "Connectez Gaston à Aircall. Gaston commentera vos appels manqués dans Aircall avec la retranscription du message et ils s'afficheront dans l'app Gaston.",
      id: "skills.services.aircall.description",
    }),
    displayName: defineMessage({
      defaultMessage: "Aircall",
      id: "skills.services.aircall.displayName",
    }),
  },
};

const Button = ({ locked, children, ...props }) => (
  <a
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    css={{
      ...buttonStyle,
      ...(locked && {
        ":hover": null,
        backgroundColor: "#E5E5E5",
        color: "#5E5E5E",
        transition: null,
      }),
      alignSelf: "flex-end",
    }}
  >
    {locked && <FontAwesomeIcon icon="lock" css={{ marginRight: "0.4rem" }} />}
    {children}
  </a>
);

const IntegrationCard = ({
  activated,
  className,
  description,
  displayName,
  imageUrl,
  loading = false,
  locked,
  name,
}) => {
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(activated);

  useEffect(() => {
    API.tokenPromise.then(setToken);
  }, []);

  const disconnect = async () => {
    setIsLoading(true);
    try {
      await API.do.get(`/${name}/unlink`);
      setIsActivated(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      css={{
        backgroundColor: "#FFF",
        borderRadius: "0.4rem",
        display: "flex",
        flexDirection: "column",
        padding: "2rem 1rem",
      }}
      className={className}
    >
      <div css={{ marginBottom: "2rem" }}>
        {loading ? (
          <Skeleton
            css={{
              marginBottom: "24px",
            }}
            duration={0.8}
            width="64px"
            height="64px"
            circle
          />
        ) : (
          <img
            src={imageUrl}
            alt={`${displayName} - Logo`}
            css={{
              backgroundColor: "#E5E5E5",
              borderRadius: "100%",
              height: "64px",
              width: "64px",
            }}
          />
        )}
      </div>

      <div
        css={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        {loading ? (
          <Skeleton duration={0.8} width="7rem" />
        ) : (
          (services.hasOwnProperty(name) && (
            <FormattedMessage {...services[name].displayName} />
          )) ||
          displayName
        )}
      </div>
      <div
        css={{
          lineHeight: "1.3",
          marginBottom: "2rem",
        }}
      >
        {loading ? (
          <>
            <Skeleton count={4} duration={0.8} />
            <Skeleton width="25%" duration={0.8} />
          </>
        ) : (
          (services.hasOwnProperty(name) && (
            <FormattedMessage {...services[name].description} />
          )) ||
          description
        )}
      </div>
      {!loading &&
        (isActivated ? (
          <FormButton
            loading={isLoading}
            disabled={isLoading}
            onClick={disconnect}
            css={{
              ...(!isLoading && { color: "red" }),
              ":hover": {
                backgroundColor: Color("white").darken(0.1).fade(0.4).string(),
              },
              alignSelf: "flex-end",
              backgroundColor: "transparent",
            }}
          >
            <FormattedMessage
              id="skills.cards.integration.CTAdisconnect"
              defaultMessage="Déconnecter"
            />
          </FormButton>
        ) : (
          <Button
            locked={locked}
            href={
              locked
                ? null
                : `${API.do.defaults.baseURL}/integrations/${name}/link?access_token=${token}`
            }
          >
            <FormattedMessage
              id="skills.cards.integration.CTAconnect"
              defaultMessage="Connecter"
            />
          </Button>
        ))}
    </div>
  );
};

export default IntegrationCard;
