/** @jsx jsx */
import { jsx } from "@emotion/core";
import { DateTime } from "luxon";
import { defineMessages, FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Link, useRouteMatch } from "react-router-dom";

import { buttonStyle, cardBorder } from "../styles";

const strings = defineMessages({
  aircall: {
    defaultMessage: "Aircall",
    id: "dashboard.cards.usage.serviceNames.aircall",
  },
  twilio: {
    defaultMessage: "Renvoi d'appel",
    id: "dashboard.cards.usage.serviceNames.twilio",
  },
  undefined: {
    defaultMessage: "Résumé",
    id: "dashboard.cards.usage.serviceNames.default",
  },
});

const UsageCard = ({ usage, className }) => {
  const { url } = useRouteMatch();
  const [date, endDate] = [
    DateTime.fromISO(usage?.date),
    DateTime.fromISO(usage?.endDate),
  ];
  return (
    <div
      css={{
        ...cardBorder,
        alignItems: "center",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem",
      }}
      className={className}
    >
      <div
        css={{
          alignSelf: "flex-start",
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormattedMessage {...strings[usage?.service]} />
      </div>
      <div css={{ color: "#AB47BC", marginBottom: "1rem" }}>
        {endDate.isValid ? (
          `${date.toLocaleString()} — ${endDate.toLocaleString()}`
        ) : (
          <FormattedMessage
            id="dashboard.cards.usage.captionThisMonth"
            defaultMessage="ce mois-ci"
          />
        )}
      </div>
      <div
        css={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        {usage?.transcripts ?? usage?.messages ?? (
          <Skeleton
            css={{ display: "inline-block" }}
            width="3rem"
            duration={0.8}
          />
        )}
      </div>
      <div css={{ marginBottom: "1rem" }}>
        <FormattedMessage
          id="dashboard.cards.usage.transcriptsLabel"
          defaultMessage="retranscriptions"
        />
      </div>
      {url !== "/dashboard/usage" && (
        <Link
          to="/dashboard/usage"
          css={{
            ...buttonStyle,
            alignSelf: "flex-end",
          }}
        >
          <FormattedMessage
            id="dashboard.cards.usage.CTA"
            defaultMessage="Consommation"
          />
        </Link>
      )}
    </div>
  );
};

export default UsageCard;
