import Color from "color";

export const buttonStyle = {
  ":hover": {
    backgroundColor: Color("white").darken(0.1).fade(0.4).string(),
  },
  background: "transparent",
  border: "none",
  borderRadius: "0.2rem",
  color: "#AB47BC",
  cursor: "pointer",
  padding: "0.4rem 1rem",
  textDecoration: "none",
  transition: "background 120ms ease-in 0s",
};

export const cardBorder = {
  border: `1px solid ${Color("white").darken(0.1)}`,
  borderRadius: "0.4rem",
  boxShadow: `0 0 0.4rem 0px ${Color("black").fade(0.9)}`,
};

export const fonts = {
  sans: {
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  serif: {
    fontFamily: "'Merriweather', serif",
  },
};

const breakpoints = [576, 768, 992, 1200];

export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
