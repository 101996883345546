import { defineMessage, defineMessages } from "react-intl";

const featuresLocalized = defineMessages({
  customGreeting: {
    defaultMessage: "Annonce personnalisée",
    id: "plans.features.customGreeting",
  },
  emailNotifications: {
    defaultMessage: "Notifications par e-mail",
    id: "plans.features.emailNotifications",
  },
  groupBilling: {
    defaultMessage: "Facturation de groupe",
    id: "plans.features.groupBilling",
  },
  integrations: {
    defaultMessage: "Intégrations",
    id: "plans.features.integrations",
  },
  landlineSupport: {
    defaultMessage: "Raccordement sur ligne fixe/standard",
    id: "plans.features.landlineSupport",
  },
  limitedTranscripts: {
    defaultMessage: "{limit} retranscriptions / mois",
    id: "plans.features.limitedTranscripts",
  },
  messageDuration: {
    defaultMessage: "{limit} secondes / message",
    id: "plans.features.messageDuration",
  },
  prioritySupport: {
    defaultMessage: "Assistance prioritaire",
    id: "plans.features.prioritySupport",
  },
  unlimitedStorage: {
    defaultMessage: "Stockage illimité",
    id: "plans.feature.unlimitedStorage",
  },
  unlimitedTranscriptsMobile: {
    defaultMessage: "Retranscriptions illimitées sur mobile",
    id: "plans.features.unlimitedTranscriptsMobile",
  },
});

const plansLocalized = {
  personal: {
    description: defineMessage({
      defaultMessage: "Pour un usage personnel et à faible volume.",
      id: "plans.personal.description",
    }),
    displayName: defineMessage({
      defaultMessage: "Personnel",
      id: "plans.personal.displayName",
    }),
    features: [
      featuresLocalized.customGreeting,
      featuresLocalized.unlimitedStorage,
      featuresLocalized.emailNotifications,
    ],
    limit: "40",
    messageDuration: "60",
    onboardingDescription: defineMessage({
      defaultMessage: "Pour un usage personnel et à faible volume.",
      id: "plans.personal.onboarding.description",
    }),
    onboardingName: defineMessage({
      defaultMessage: "Formule Personnelle",
      id: "plans.personal.onboarding.name",
    }),
    price: [
      defineMessage({
        defaultMessage: "3,99 €",
        id: "plans.personal.price.value",
      }),
      defineMessage({
        defaultMessage: "TTC",
        id: "plans.personal.price.rightCaption",
      }),
      defineMessage({
        defaultMessage: "par mois, sans engagement",
        id: "plans.personal.price.bottomCaption",
      }),
    ],
  },

  professional: {
    description: defineMessage({
      defaultMessage:
        "Messages illimités sur mobiles, fonctionnalités avancées.",
      id: "plans.professional.description",
    }),
    displayName: defineMessage({
      defaultMessage: "Professionnel",
      id: "plans.professional.displayName",
    }),
    features: [
      featuresLocalized.customGreeting,
      featuresLocalized.unlimitedStorage,
      featuresLocalized.emailNotifications,
    ],
    featuresHighlighted: [
      featuresLocalized.groupBilling,
      featuresLocalized.prioritySupport,
      featuresLocalized.integrations,
    ],
    limit: featuresLocalized.unlimitedTranscriptsMobile,
    messageDuration: "120",
    onboardingDescription: defineMessage({
      defaultMessage: "Travaillez plus efficacement grâce à Gaston",
      id: "plans.professional.onboarding.description",
    }),
    onboardingName: defineMessage({
      defaultMessage: "Formule Professionnelle",
      id: "plans.professional.onboarding.name",
    }),
    price: [
      defineMessage({
        defaultMessage: "15 €",
        id: "plans.professional.price.value",
      }),
      defineMessage({
        defaultMessage: "HT",
        id: "plans.professional.price.rightCaption",
      }),
      defineMessage({
        defaultMessage: "par utilisateur par mois",
        id: "plans.professional.price.bottomCaption",
      }),
    ],
  },
};

export { plansLocalized, featuresLocalized };
