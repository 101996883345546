/* eslint-disable react/display-name */
/** @jsx jsx */

import { jsx } from "@emotion/core";
import Color from "color";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import API from "../API";
import IntegrationCard from "../components/IntegrationCard";
import PageTitle from "../components/PageTitle";
import { mq, buttonStyle, cardBorder } from "../styles";

const LockedCard = () => (
  <div
    css={{
      ...cardBorder,
      backgroundColor: "#FFF",
      display: "flex",
      flexDirection: "column",
      margin: "0 0 1.4rem",
      padding: "2rem 1rem",
    }}
  >
    <div
      css={{
        fontSize: "1.4rem",
        fontWeight: "bold",
        marginBottom: "1rem",
      }}
    >
      <FormattedMessage
        id="skills.cards.featureLocked.title"
        defaultMessage="Fonctionnalité verrouillée"
      />
    </div>
    <div
      css={{
        lineHeight: "1.3",
        marginBottom: "2rem",
      }}
    >
      <FormattedMessage
        id="skills.cards.featureLocked.caption"
        defaultMessage="Votre abonnement ne vous donne pas encore accès aux Skills. Mettez à
      niveau vers une version supérieure et découvrez les Skills et d'avantage
      de fonctionnalités exclusives."
      />
    </div>
    <Link
      to="/dashboard/subscription"
      css={{
        ...buttonStyle,
        ":hover": {
          backgroundColor: Color("#AB47BC").darken(0.1).string(),
        },
        alignSelf: "flex-end",
        backgroundColor: "#AB47BC",
        color: "#FFF",
      }}
    >
      <FormattedMessage
        id="skills.cards.featureLocked.CTAupgrade"
        defaultMessage="Mettre à niveau"
      />
    </Link>
  </div>
);

const Integrations = () => {
  const {
    data: integrationsData = {
      integrations: [{ loading: true, name: "placeholder" }],
    },
  } = API.get("/integrations");
  const { data: userData } = API.get("/user");
  const { data: plansData } = API.get("/plans");

  const plan = plansData?.plans?.find((p) => p._id === userData?.user?.planId);
  const hasAccess = plan?.allowedIntegrations?.[0];

  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        margin: "1rem",
        [mq[0]]: {
          margin: "1rem 10vw",
        },
      }}
    >
      <PageTitle
        title={<FormattedMessage id="skills.title" defaultMessage="Skills" />}
        caption={
          <FormattedMessage
            id="skills.caption"
            defaultMessage="Rajoutez des fonctionnalités à Gaston en activant des Skills. <a>En savoir plus →</a>"
            values={{
              a: (chunks) => (
                <a
                  css={{
                    color: "#AB47BC",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                  href="https://support.gaston.tel/portal/fr/kb/gaston/skills"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        }
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "360px",
        }}
      >
        {plansData && userData && !hasAccess && <LockedCard />}

        {integrationsData?.integrations?.map(
          ({ name, activated, description, displayName, imageUrl }) => (
            <IntegrationCard
              css={{
                ...cardBorder,
                margin: "0 0 1.4rem",
              }}
              key={name}
              locked={!plan?.allowedIntegrations?.includes(name)}
              {...{ activated, description, displayName, imageUrl, name }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Integrations;
