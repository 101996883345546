/** @jsx jsx */

import Bugsnag from "@bugsnag/js";
import { jsx } from "@emotion/core";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { mutate } from "swr";

import API from "../../../API";
import { Button } from "../../Form";
import PlanCard, { PlanFeature } from "../../PlanCard";
import { featuresLocalized, plansLocalized } from "../../plansLocalized";
import { H1 } from "../shared";

const Plan = ({ name }) => {
  const intl = useIntl();
  const plan = plansLocalized[name];

  return (
    <PlanCard
      css={{ marginBottom: "2rem" }}
      name={intl.formatMessage(plan.onboardingName)}
      description={intl.formatMessage(plan.onboardingDescription)}
      price={[
        intl.formatMessage(plan.price[0]),
        intl.formatMessage(plan.price[1]),
        intl.formatMessage(plan.price[2]),
      ]}
      //
      topFeatures={[
        <PlanFeature
          css={{ marginBottom: "4px" }}
          icon="voicemail"
          key="transcripts"
        >
          {typeof plan.limit === "string" ? (
            <FormattedMessage
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...featuresLocalized.limitedTranscripts}
              values={{ limit: plan.limit }}
            />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <FormattedMessage {...plan.limit} />
          )}
        </PlanFeature>,
        <PlanFeature icon="stopwatch" key="message-duration">
          <FormattedMessage
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...featuresLocalized.messageDuration}
            values={{ limit: plan.messageDuration }}
          />
        </PlanFeature>,
      ]}
    >
      {plan.features.map((f) => (
        <PlanFeature key={f.id}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormattedMessage {...f} />
        </PlanFeature>
      ))}

      {plan.featuresHighlighted?.map((f) => (
        <PlanFeature key={f.id} css={{ fontWeight: "600" }}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormattedMessage {...f} />
        </PlanFeature>
      ))}
    </PlanCard>
  );
};

const TrialStep = ({ next, type }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    try {
      await API.do.post("/subscriptions/trial", {
        planName: type,
      });
      await mutate("/user");
      next();
    } catch (err) {
      // TODO: Handle errors
      Bugsnag.notify(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div css={{ marginBottom: "2rem" }}>
        <H1>
          <FormattedMessage
            id="onboarding.trial.title"
            defaultMessage="Essayez Gaston gratuitement pendant 15 jours"
          />
        </H1>
        <div>
          <FormattedMessage
            id="onboarding.trial.caption"
            defaultMessage="Pas besoin de carte de crédit, vous validerez votre formule à la fin
          de votre essai."
          />
        </div>
      </div>
      <Plan name={type} />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <small
          css={{
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          <FormattedMessage
            id="onboarding.trial.termsNotice"
            defaultMessage="En continuant, vous acceptez les <TermsLink>Conditions d'utilisation</TermsLink>"
            values={{
              // eslint-disable-next-line react/display-name
              TermsLink: (chunks) => (
                <a
                  href="https://gaston.tel/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </small>
        <Button
          css={{ fontWeight: "bold" }}
          disabled={isLoading}
          loading={isLoading}
          onClick={submit}
          type="submit"
        >
          <FormattedMessage
            id="onboarding.trial.CTAstartTrial"
            defaultMessage="Commencer mon essai"
          />
        </Button>
      </div>
    </>
  );
};

export default TrialStep;
