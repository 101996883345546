/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "color";
import { DateTime } from "luxon";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import API from "../API";
import { Button } from "../components/Form";
import PageTitle from "../components/PageTitle";
import PlanCard from "../components/PlanCard";
import SubscriptionModal from "../components/SubscriptionModal";
import { plansLocalized } from "../components/plansLocalized";
import { redirectToCustomerPortal } from "../lib";
import { mq } from "../styles";

const SubscribeButton = styled.button({
  ":hover": {
    backgroundColor: Color("white").darken(0.1).fade(0.4).string(),
  },
  background: "transparent",
  border: "none",
  color: "#AB47BC",
  cursor: "pointer",
  fontWeight: "bold",
  textAlign: "start",
  textDecoration: "underline",
  transition: "background 120ms ease-in 0s",
});

const InfoPanel = ({ children, className }) => (
  <div
    css={{
      alignItems: "center",
      backgroundColor: "#F5F1F6",
      borderRadius: "0.4rem",
      display: "flex",
      padding: "0.4rem 0.8rem",
    }}
  >
    <FontAwesomeIcon
      icon="info-circle"
      css={{
        color: "#AB47BC",
        display: "block",
        marginRight: "0.4rem",
      }}
    />
    <div
      css={{
        display: "flex",
        flexWrap: "wrap ",
      }}
      className={className}
    >
      {children}
    </div>
  </div>
);

const Subscription = () => {
  const { data: userData } = API.get("/user");
  const { data: plansData } = API.get("/plans");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const plan = plansData?.plans?.find((p) => p._id === userData?.user?.planId);

  const localizedPlan = plansLocalized[plan?.name];

  const isInTrial =
    new Date() < new Date(userData?.user?.endTrialDate || Date.now());

  return (
    <SkeletonTheme color="#E0E0E0">
      <div
        css={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          margin: "1rem",
          [mq[0]]: {
            margin: "1rem 10vw",
          },
        }}
      >
        <PageTitle
          title={
            <FormattedMessage
              id="subscription.title"
              defaultMessage="Abonnement"
            />
          }
        />
        <PlanCard
          css={{ width: "360px" }}
          name={
            (localizedPlan && (
              <FormattedMessage {...localizedPlan.displayName} />
            )) ||
            plan?.displayName || <Skeleton width="6rem" duration={0.8} />
          }
          description={
            (localizedPlan && (
              <FormattedMessage {...localizedPlan.description} />
            )) ||
            plan?.description || <Skeleton duration={0.8} />
          }
          ctaComponent={
            <div
              css={{
                alignItems: "center",
                alignSelf: "center",
                display: "flex",
                marginTop: "1rem",
              }}
            >
              {userData ? (
                <>
                  <div
                    css={{
                      backgroundColor: userData?.user.expired
                        ? "#f44336"
                        : "#4CAF50",
                      borderRadius: "50%",
                      height: ".6rem",
                      marginRight: ".4rem",
                      width: ".6rem",
                    }}
                  />
                  <div
                    css={{
                      fontSize: "1.2rem",
                    }}
                  >
                    {(userData?.user?.expired && (
                      <FormattedMessage
                        id="subscription.cards.plan.status.expired"
                        defaultMessage="Expiré"
                      />
                    )) ||
                      (isInTrial && (
                        <FormattedMessage
                          id="subscription.cards.plan.status.trial"
                          defaultMessage="En essai"
                        />
                      )) || (
                        <FormattedMessage
                          id="subscription.cards.plan.status.active"
                          defaultMessage="Actif"
                        />
                      )}
                  </div>
                </>
              ) : (
                <Skeleton width="6rem" height="1.2rem" duration={0.8} />
              )}
            </div>
          }
        />
        {(isInTrial && (
          <InfoPanel>
            <FormattedMessage
              id="subscription.panels.trial"
              defaultMessage="Merci d'essayer Gaston ! Votre essai se termine {EndTrialDateFormatted}. <Button>Appuyez-ici pour souscrire dès maintenant.</Button>"
              values={{
                // eslint-disable-next-line react/display-name
                Button: (chunks) => (
                  <SubscribeButton
                    onClick={() => setIsModalOpen(true)}
                    type="button"
                  >
                    {chunks}
                  </SubscribeButton>
                ),
                EndTrialDateFormatted: DateTime.fromISO(
                  userData?.user?.endTrialDate
                ).toRelativeCalendar(),
              }}
            />
          </InfoPanel>
        )) ||
          (userData?.user?.stripeCustomerId && (
            <Button onClick={redirectToCustomerPortal}>
              <FormattedMessage
                id="subscriptions.manageCTA"
                defaultMessage="Gérer l'abonnement"
              />
            </Button>
          )) ||
          (!userData?.user?.expired && (
            <InfoPanel>
              <FormattedMessage
                id="subscription.panels.manageFromMobile"
                defaultMessage="Vous avez souscrit depuis votre téléphone. Vous ne pouvez gérer
              votre abonnement que depuis l'application. <a>En savoir plus →</a>"
                values={{
                  a: (chunks) => (
                    <>
                      &nbsp;
                      <a
                        css={{
                          color: "#AB47BC",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                        href="https://support.gaston.tel/portal/fr/kb/articles/pourquoi-ne-puis-je-pas-g%C3%A9rer-mon-abonnement-depuis-la-web-app"
                      >
                        {chunks}
                      </a>
                    </>
                  ),
                }}
              />
            </InfoPanel>
          )) ||
          (userData.user && (
            <InfoPanel css={{ alignItems: "baseline" }}>
              <FormattedMessage
                id="subscription.panels.expired"
                defaultMessage="Vous n'êtes actuellement pas abonné.&nbsp;<Button>Souscrivez pour continuer d'accéder au service.</Button>"
                values={{
                  Button: (chunks) => (
                    <SubscribeButton
                      onClick={() => setIsModalOpen(true)}
                      type="button"
                    >
                      {chunks}
                    </SubscribeButton>
                  ),
                }}
              />
            </InfoPanel>
          ))}
      </div>
      {isModalOpen && (
        <SubscriptionModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      )}
    </SkeletonTheme>
  );
};

export default Subscription;
