import Bugsnag from "@bugsnag/js";

import API from "./API";

export const redirectToCustomerPortal = async () => {
  try {
    const {
      data: { location },
    } = await API.do.get("/stripe/generate-portal-session");

    window.location = location;
  } catch (error) {
    Bugsnag.notify(error);
  }
};

const intervals = {
  day: "jour",
  month: "mois",
  week: "semaine",
  year: "an",
};

// TODO: Add support for intervalCount
// e.g.: 12 € / 3 mois
export const formatInterval = (interval) =>
  interval ? ` / ${intervals[interval]}` : "";

export const formatPrice = (cents, currency = "eur", interval) =>
  new Intl.NumberFormat("fr-FR", {
    currency: currency.toUpperCase(),
    style: "currency",
  }).format(cents / 100) + formatInterval(interval);

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;