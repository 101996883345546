/** @jsx jsx */

import { jsx } from "@emotion/core";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import API from "../../../API";
import { emailRegex } from "../../../lib";
import { Button, Checkbox } from "../../Form";
import { H1, TextInput } from "../shared";

const IdentityStep = ({
  values: { firstName, lastName, email, acceptsPromotionalCommunications },
  setValue,
  next,
  setError,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setIsValid(
      firstName.length > 0 && lastName.length > 0 && email.match(emailRegex)
    );
  }, [firstName, lastName, email]);

  const submit = async () => {
    if (isValid) {
      setIsLoading(true);
      try {
        await API.do.post("/user", {
          email,
          firstName,
          lastName,
          parameters: {
            acceptsPromotionalCommunications,
          },
        });
        next();
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <>
      <H1>
        <FormattedMessage
          id="onboarding.identity.title"
          defaultMessage="Parlez-nous de vous"
        />
      </H1>
      <div>
        <TextInput
          id="firstName"
          name="firstName"
          onChange={(e) => setValue("firstName", e.target.value)}
          placeholder="Alex"
          title={intl.formatMessage({
            defaultMessage: "Prénom",
            id: "onboarding.identity.inputs.firstName.label",
          })}
          type="text"
          value={firstName}
        />
        <TextInput
          id="lastName"
          name="lastName"
          onChange={(e) => setValue("lastName", e.target.value)}
          placeholder="Dupont"
          title={intl.formatMessage({
            defaultMessage: "Nom",
            id: "onboarding.identity.inputs.lastName.label",
          })}
          type="text"
          value={lastName}
        />
        <TextInput
          id="email"
          onChange={(e) => setValue("email", e.target.value)}
          name="email"
          placeholder={intl.formatMessage({
            defaultMessage: "exemple@domaine.fr",
            id: "onboarding.identity.inputs.email.placeholder",
          })}
          title={intl.formatMessage({
            defaultMessage: "Adresse e-mail",
            id: "onboarding.identity.inputs.email.label",
          })}
          type="email"
          value={email}
        />
        <Checkbox
          onChange={(e) =>
            setValue("acceptsPromotionalCommunications", e.target.checked)}
          checked={acceptsPromotionalCommunications}
          type="checkbox"
          title={
            <small>
              <FormattedMessage
                id="onboarding.identity.marketingChoiceNotice"
                defaultMessage="J'accepte de recevoir un petit e-mail de temps en temps (nouvelles
              fonctionnalités, offres…)"
              />
            </small>
          }
        />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          margin: "2rem 0 0",
        }}
      >
        <small
          css={{
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          <FormattedMessage
            id="onboarding.identity.privacyNotice"
            defaultMessage="En continuant, vous acceptez la <PrivacyPolicyLink>Charte de Confidentialité</PrivacyPolicyLink>"
            values={{
              // eslint-disable-next-line react/display-name
              PrivacyPolicyLink: (chunks) => (
                <a
                  href="https://gaston.tel/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </small>
        <Button
          disabled={isLoading || !isValid}
          loading={isLoading}
          onClick={submit}
          type="submit"
        >
          <FormattedMessage
            id="onboarding.identity.CTAcontinue"
            defaultMessage="Continuer"
          />
        </Button>
      </div>
    </>
  );
};

export default IdentityStep;
