/** @jsx jsx */

import { jsx } from "@emotion/core";
import { faLanguage, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import useAuth from "../context/auth";
import { mq, buttonStyle, fonts } from "../styles";

const Link = ({ isAuthenticated, children, ...props }) => {
  const El = isAuthenticated ? NavLink : "a";

  return (
    <El
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(isAuthenticated ? { to: "/" } : { href: "https://gaston.tel" })}
    >
      {children}
    </El>
  );
};

const Header = ({ useLocale }) => {
  const { setAuthenticated, isAuthenticated } = useAuth();
  const [locale, setLocale] = useLocale;

  const handleChangeLocale = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
  };

  return (
    <div
      css={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        margin: "0 1rem",
        padding: "1rem 1.4rem",
        [mq[0]]: {
          margin: "0 10vw",
        },
      }}
    >
      <Link
        css={{
          ...fonts.serif,
          color: "#AB47BC",
          fontSize: "1.6rem",
          fontWeight: "bold",
          textDecoration: "none",
        }}
        isAuthenticated={isAuthenticated}
      >
        Gaston
      </Link>
      <div css={{ display: "flex" }}>
        {isAuthenticated && (
          <button
            type="button"
            css={{
              ...buttonStyle,
              alignItems: "center",
              color: "red",
              display: "flex",
            }}
            onClick={() => {
              localStorage.clear();
              setAuthenticated(false);
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span
              css={{
                display: "none",
                margin: "0 0 0 .2rem",
                [mq[0]]: {
                  display: "inherit",
                },
              }}
            >
              <FormattedMessage
                id="header.logOut"
                defaultMessage="Déconnexion"
              />
            </span>
          </button>
        )}

        {/* Language Switcher */}
        {!isAuthenticated && (
          <div
            css={{
              alignItems: "baseline",
              display: "flex",
            }}
          >
            <label
              htmlFor="languageSwitcher"
              css={{
                display: "block",
                marginInlineEnd: "0.2rem",
              }}
            >
              <FontAwesomeIcon icon={faLanguage} title="Choose a language" />
            </label>
            <select
              name="language"
              id="languageSwitcher"
              value={locale}
              onChange={handleChangeLocale}
            >
              <option value="fr">Français</option>
              <option value="en">English</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
