/* eslint-disable react/display-name */
/** @jsx jsx */

import Bugsnag from "@bugsnag/js";
import { jsx } from "@emotion/core";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import API from "../API";
import DashboardHeader from "../components/DashboardHeader";
import ExpiredCard from "../components/ExpiredCard";
import PageTitle from "../components/PageTitle";
import PromoCard from "../components/PromoCard";
import TrialCard from "../components/TrialCard";
import UsageCard from "../components/UsageCard";
import { mq } from "../styles";

import Integrations from "./integrations";
import Subscription from "./subscription";
import Usage from "./usage";

const DashboardComponent = () => {
  const intl = useIntl();

  const { data: userData } = API.get("/user");
  const { data: plansData } = API.get("/plans");

  const plan = plansData?.plans?.find((p) => p._id === userData?.user?.planId);

  if (userData?.user && !userData?.user?.planId) {
    return <Redirect to={{ pathname: "/onboarding" }} />;
  }

  if (userData?.user) {
    Bugsnag.setUser(
      userData.user._id,
      userData.user.email,
      `${userData.user.firstName} ${userData.user.lastName}`
    );
  }

  return (
    <div
      css={{
        margin: "1rem",
        [mq[0]]: {
          margin: "1rem 10vw",
        },
      }}
    >
      <PageTitle
        title={
          <FormattedMessage
            id="dashboard.title"
            defaultMessage="Bienvenue, {FirstName}."
            values={{
              FirstName: userData?.user?.firstName || (
                <Skeleton
                  css={{ display: "inline-block" }}
                  width="4rem"
                  duration={0.8}
                />
              ),
            }}
          />
        }
        caption={
          <FormattedMessage
            id="dashboard.title.caption"
            defaultMessage="Retrouvez ici un aperçu de votre compte et des informations sur Gaston."
          />
        }
      />
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "100%",
          maxWidth: "360px",
          rowGap: "1rem",
        }}
      >
        {userData?.user?.expired && <ExpiredCard />}

        {new Date() < new Date(userData?.user?.endTrialDate || Date.now()) && (
          <TrialCard endTrialDate={userData?.user?.endTrialDate} />
        )}

        {/* Onboarding Cards */}

        {!userData?.user.parameters?.activated && (
          <>
            <PromoCard
              title={
                <FormattedMessage
                  id="dashboard.cards.onboarding.mobileAppNotice.title"
                  defaultMessage="Bien démarrer : installer Gaston sur votre téléphone"
                />
              }
              caption={
                <FormattedMessage
                  id="dashboard.cards.onboarding.mobileAppNotice.caption"
                  defaultMessage="Grâce à l'app Gaston, remplacez votre répondeur mobile, personnalisez votre annonce et accédez à toutes vos retranscriptions."
                />
              }
              ctas={[
                {
                  content: "iPhone",
                  href: "https://itunes.apple.com/us/app/gaston/id1308164057",
                },
                {
                  content: "Android",
                  href:
                    "https://play.google.com/store/apps/details?id=tel.gaston.app",
                },
              ]}
            />
            {(plan?.name.includes("professional") ||
              plan?.name.includes("enterprise")) && (
              <PromoCard
                title={
                  <FormattedMessage
                    id="dashboard.cards.onboarding.landlineNotice.title"
                    defaultMessage="Bien démarrer : installer Gaston sur une ligne fixe"
                  />
                }
                caption={
                  <FormattedMessage
                    id="dashboard.cards.onboarding.landlineNotice.caption"
                    defaultMessage="Contactez-nous afin de brancher Gaston sur votre ligne fixe ou votre standard."
                  />
                }
                ctas={[
                  {
                    content: (
                      <FormattedMessage
                        id="dashboard.cards.onboarding.landlineNotice.CTA"
                        defaultMessage="Nous contacter"
                      />
                    ),
                    href: `mailto:contact@gaston.tel?subject=${encodeURIComponent(
                      intl.formatMessage({
                        defaultMessage: "Connexion à une ligne fixe",
                        id:
                          "dashboard.cards.onboarding.landlineNotice.CTA.emailSubject",
                      })
                    )}`,
                  },
                ]}
              />
            )}
          </>
        )}

        <UsageCard usage={userData?.user?.usage || {}} />
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { path } = useRouteMatch();
  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <DashboardHeader
        css={{
          borderBottom: "1px solid #E5E5E5",
          margin: "0 1rem",
          [mq[0]]: {
            margin: "0 10vw",
          },
        }}
      />
      <Switch>
        <Route exact path={path} component={DashboardComponent} />
        <Route path={`${path}/integrations`} component={Integrations} />
        <Route path={`${path}/usage`} component={Usage} />
        <Route path={`${path}/subscription`} component={Subscription} />
      </Switch>
    </div>
  );
};

export default Dashboard;
