/* eslint-disable react-hooks/rules-of-hooks */
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import qs from "qs";
import useSWR from "swr";

export default class API {
  static token = null;

  static clientId = "gaston-webapp";

  static clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  static redirectUri = `${window.location.origin}/login`;

  static tokenPromise;

  static refreshingToken = null;

  static do = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
  });

  static post = (key, data, options) =>
    useSWR(
      [key, data],
      (url, _data) => API.do.post(url, _data).then((res) => res.data),
      options
    );

  static get = (key, options) =>
    useSWR(key, (url) => API.do.get(url).then((res) => res.data), options);

  static authenticate = async (data, config) => {
    const authReq = await API.do.post(
      "/oauth/auth",
      {
        client_id: API.clientId,
        redirect_uri: API.redirectUri,
        response_type: "code",
        ...data,
      },
      config
    );

    const tokenReq = await API.do.post(
      "/oauth/token",
      qs.stringify({
        client_id: API.clientId,
        client_secret: API.clientSecret,
        code: authReq.data.code,
        grant_type: "authorization_code",
        redirect_uri: API.redirectUri,
      })
    );

    if (!tokenReq.data.access_token) throw new Error(tokenReq.data.message);

    localStorage.setItem("token", tokenReq.data.access_token);
    localStorage.setItem("refreshToken", tokenReq.data.refresh_token);

    API.tokenPromise = Promise.resolve(tokenReq.data.access_token);
  };

  static refreshToken = async () => {
    Bugsnag.leaveBreadcrumb("[API] Refreshing token", null, "request");

    const refreshToken = localStorage.getItem("refreshToken");

    const req = await API.do.post(
      "oauth/token",
      qs.stringify({
        client_id: API.clientId,
        client_secret: API.clientSecret,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
      {
        // TODO: Verify if Authorization is required for oauth/token
        headers: {
          Authorization: `Bearer ${await API.tokenPromise}`,
        },
      }
    );

    if (!req.data.access_token) throw new Error(req.data.message);

    localStorage.setItem("token", req.data.access_token);
    localStorage.setItem("refreshToken", req.data.refresh_token);

    API.tokenPromise = Promise.resolve(req.data.access_token);
    API.refreshingToken = null;
  };
}
