/** @jsx jsx */
import { jsx, keyframes } from "@emotion/core";
import Color from "color";

import { buttonStyle } from "../styles";

export const TextInput = ({
  id,
  title,
  className,
  helperText,
  error,
  ...props
}) => (
  <label
    className={className}
    css={[
      {
        ":focus-within": {
          color: "#AB47BC",
        },
        display: "flex",
        flexDirection: "column",
        transition: "color 120ms ease-in 0s",
      },
      error && {
        "> input": {
          borderColor: "red",
          color: "red",
        },
        "> small": {
          color: "red",
        },
        color: "red",
      },
    ]}
    htmlFor={id}
  >
    {title}
    <input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      css={{
        "::placeholder": {
          color: "#5E5E5E",
        },
        ":focus": {
          borderColor: "#AB47BC",
        },
        ":hover": {
          borderColor: "#BEBEBE",
        },
        appearance: "none",
        border: "1px solid #E5E5E5",
        borderRadius: "0.2rem",
        margin: "0.4rem 0 0",
        padding: "0.4rem 0.8rem",
        transition: "border 120ms ease-in 0s",
      }}
      name={id}
      id={id}
    />
    {(error || helperText) && (
      <small css={{ color: "#5E5E5E", margin: "0.4rem 0 0" }}>
        {error || helperText}
      </small>
    )}
  </label>
);

export const Checkbox = ({ id, title, className, error, ...props }) => (
  <div className={className}>
    <label
      css={[
        {
          ":hover": {
            backgroundColor: "#EFEFEF",
          },
          alignItems: "center",
          borderRadius: "0.2rem",
          display: "flex",
          padding: "0.4rem 0.8rem",
          transition: "background 120ms ease-in 0s",
        },
        error && {
          color: "red",
        },
      ]}
      htmlFor={id}
    >
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type="checkbox"
        css={{
          margin: "0 0.8rem 0 0",
        }}
        name={id}
        id={id}
      />
      {title}
    </label>
    {error && (
      <small css={{ color: "red", margin: "0.4rem 0 0" }}>{error}</small>
    )}
  </div>
);

export const Radio = ({ id, title, className, error, ...props }) => (
  <div className={className}>
    <label
      css={[
        {
          ":hover": {
            backgroundColor: "#EFEFEF",
          },
          alignItems: "center",
          borderRadius: "0.2rem",
          display: "flex",
          padding: "0.4rem 0.8rem",
          transition: "background 120ms ease-in 0s",
        },
        error && {
          color: "red",
        },
      ]}
      htmlFor={id}
    >
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type="radio"
        css={{
          margin: "0 0.8rem 0 0",
        }}
        name={id}
        id={id}
      />
      {title}
    </label>
    {error && (
      <small css={{ color: "red", margin: "0.4rem 0 0" }}>{error}</small>
    )}
  </div>
);

const pulse = keyframes`
  0% {
    opacity: 0.2
  }

  25% {
    opacity: 1
  }

  100% {
    opacity: 0.2
  }
`;

export const Loading = ({ className }) => (
  <span
    className={className}
    css={{
      "> span": {
        ":nth-of-type(2)": {
          animationDelay: "0.2s",
        },
        ":nth-of-type(3)": {
          animationDelay: "0.4s",
        },
        animation: `${pulse} 1.4s infinite`,
        opacity: "0.2",
      },
    }}
  >
    <span aria-hidden>•</span>
    <span aria-hidden>•</span>
    <span aria-hidden>•</span>
  </span>
);

export const Button = ({
  "aria-label": ariaLabel,
  children,
  disabled,
  loading,
  ...props
}) => (
  <button
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    type="button"
    css={{
      ...buttonStyle,
      ":disabled": {
        backgroundColor: "#5E5E5E",
      },
      ":hover": {
        backgroundColor: Color("#AB47BC").darken(0.1).string(),
      },
      backgroundColor: "#AB47BC",
      color: "#FFF",
      transition: "background 120ms ease-in 0s",
    }}
    aria-label={loading ? "Chargement…" : ariaLabel}
    disabled={loading || disabled}
  >
    {loading ? <Loading /> : children}
  </button>
);
