/** @jsx jsx */

import { jsx } from "@emotion/core";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import API from "../../../API";
import { Button } from "../../Form";
import { H1, TextInput } from "../shared";

const BusinessInfoStep = ({
  next,
  setError,
  setValue,
  values: { jobTitle, company },
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setIsValid(jobTitle.length > 0 && company.length > 0);
  }, [jobTitle, company]);

  const submit = async () => {
    if (isValid) {
      setIsLoading(true);
      try {
        await API.do.post("/user", {
          company,
          jobTitle,
        });
        next();
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <H1>
        <FormattedMessage
          id="onboarding.business.title"
          defaultMessage="Dites-nous en plus sur votre profession"
        />
      </H1>
      <div>
        <TextInput
          id="jobTitle"
          name="jobTitle"
          onChange={(e) => setValue("jobTitle", e.target.value)}
          placeholder={intl.formatMessage({
            defaultMessage: "Directeur Commercial",
            id: "onboarding.business.inputs.jobTitle.placeholder",
          })}
          title={intl.formatMessage({
            defaultMessage: "Votre fonction",
            id: "onboarding.business.inputs.jobTitle.label",
          })}
          type="text"
          value={jobTitle}
        />
        <TextInput
          id="company"
          name="company"
          onChange={(e) => setValue("company", e.target.value)}
          placeholder={intl.formatMessage({
            defaultMessage: "Dupont SARL",
            id: "onboarding.business.inputs.companyName.placeholder",
          })}
          title={intl.formatMessage({
            defaultMessage: "Entreprise",
            id: "onboarding.business.inputs.companyName.label",
          })}
          type="text"
          value={company}
        />
      </div>
      <Button
        disabled={isLoading || !isValid}
        loading={isLoading}
        onClick={submit}
        type="submit"
      >
        <FormattedMessage
          id="onboarding.business.CTAcontinue"
          defaultMessage="Continuer"
        />
      </Button>
    </>
  );
};

export default BusinessInfoStep;
