/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

import { mq, buttonStyle, cardBorder } from "../styles";

const CTA = styled("a")(buttonStyle);

const PromoCard = ({ className, title, caption, imageSrc, ctas }) => (
  <div
    css={{
      backgroundColor: "#FFF",
      ...cardBorder,
      padding: "1rem",
      [mq[0]]: {
        alignItems: "center",
        display: "flex",
      },
    }}
    className={className}
  >
    {imageSrc && (
      <img
        src={imageSrc}
        alt=""
        css={{
          backgroundColor: "#E5E5E5",
          borderRadius: "14%",
          height: "80px",
          margin: "0 0 1rem",
          width: "80px",
          [mq[0]]: {
            flexShrink: 0,
            margin: "0 1rem 0 0",
          },
        }}
      />
    )}
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          marginBottom: "0.4rem",
        }}
      >
        {title}
      </div>
      <div css={{ marginBottom: "1rem" }}>{caption}</div>
      <div
        css={{
          alignSelf: "flex-end",
          display: "flex",
        }}
      >
        {ctas.map(({ content, href }, index) => (
          <CTA
            css={index < ctas.length && { marginRight: "0.2rem" }}
            href={href}
            key={content.toString().toLowerCase()}
          >
            {content}
          </CTA>
        ))}
      </div>
    </div>
  </div>
);

export default PromoCard;
