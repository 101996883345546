/* eslint-disable react/display-name */
/** @jsx jsx */

import { jsx } from "@emotion/core";
import Color from "color";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import API from "../API";
import {
  BusinessInfoStep,
  TrialStep,
  UseTypeStep,
  IdentityStep,
} from "../components/Onboarding";
import { mq } from "../styles";

const Onboarding = () => {
  const { data: userData = {} } = API.get("/user");
  const { user } = userData;

  const history = useHistory();

  const [step, setStep] = useState("identity");
  const [error, setError] = useState();
  const [values, _setValues] = useState({
    acceptsPromotionalCommunications:
      user?.parameters?.acceptsPromotionalCommunications || false,
    company: user?.company || "",
    email: user?.email || "",
    firstName: user?.firstName || "",
    jobTitle: user?.jobTitle || "",
    lastName: user?.lastName || "",
    type: null,
  });

  useEffect(() => {
    _setValues((v) => ({
      ...v,
      acceptsPromotionalCommunications:
        user?.parameters?.acceptsPromotionalCommunications || false,
      company: user?.company || "",
      email: user?.email || "",
      firstName: user?.firstName || "",
      jobTitle: user?.jobTitle || "",
      lastName: user?.lastName || "",
    }));
  }, [user]);

  const next = () => {
    switch (step) {
      case "identity":
        setStep("useType");
        break;
      case "useType":
        if (values.type === "professional") setStep("businessInfo");
        else setStep("trial");
        break;
      case "businessInfo":
        setStep("trial");
        break;
      case "trial":
        history.push("/dashboard");
        break;
      default:
        break;
    }
  };

  const setValue = (id, value) => _setValues({ ...values, [id]: value });

  return (
    <div
      css={{
        alignItems: "center",
        borderTop: "1px solid #E5E5E5",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 1rem",
        padding: "2rem 0",
        [mq[0]]: {
          margin: "0 10vw",
        },
      }}
    >
      <form
        css={{
          [mq[0]]: {
            border: `1px solid ${Color("white").darken(0.1)}`,
            borderRadius: "0.4rem",
            boxShadow: `0 0 0.4rem 0px ${Color("black").fade(0.9)}`,
            maxWidth: "320px",
            padding: "2rem",
          },
          alignItems: "stretch",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "420px",
          width: "100%",
        }}
        onSubmit={(event) => event.preventDefault()}
      >
        {error && (
          <div
            css={{
              backgroundColor: "red",
              borderRadius: "0.2rem",
              color: "#FFF",
              margin: "0 0 1rem",
              padding: "0.4rem 0.8rem",
            }}
          >
            <FormattedMessage
              defaultMessage="Une erreur s'est produite, merci de réessayer"
              id="onboarding.errorMessage"
            />
          </div>
        )}

        {step === "identity" && (
          <IdentityStep
            next={next}
            setError={setError}
            setValue={setValue}
            values={values}
          />
        )}

        {step === "useType" && (
          <UseTypeStep next={next} setValue={setValue} values={values} />
        )}

        {step === "businessInfo" && (
          <BusinessInfoStep next={next} setValue={setValue} values={values} />
        )}

        {step === "trial" && <TrialStep next={next} type={values.type} />}
      </form>
    </div>
  );
};

export default Onboarding;
