/** @jsx jsx */

import { jsx } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React from "react";

import { fonts } from "../styles";

const PlanFeature = ({ children, className, icon }) => (
  <div
    css={{
      alignItems: "center",
      display: "flex",
      fontSize: "1rem",
      marginBottom: "4px",
    }}
    className={className}
  >
    <FontAwesomeIcon
      css={{
        color: "#AB47BC",
        fontSize: "1rem",
        marginRight: "4px",
      }}
      icon={icon}
    />
    {children}
  </div>
);

PlanFeature.defaultProps = {
  icon: "check",
};

const PlanCard = ({
  children,
  ctaComponent,
  description,
  name,
  price,
  topFeatures,
  className,
}) => (
  <div className={className}>
    <div
      css={{
        background: "#f5f1f6",
        borderRadius: "0.4rem",
        boxShadow: `-1rem -1rem 2rem #f3f3f3,
          1rem 1rem 2rem rgba(119, 55, 170, 0.1),
          inset .4rem .4rem 1rem rgba(236, 236, 236, 0.5)`,
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.4rem",
        padding: "1.4rem",
      }}
    >
      <div
        css={{
          marginBottom: "0.8rem",
          textAlign: "center",
        }}
      >
        <div
          css={{
            ...fonts.serif,
            fontSize: "1.3rem",
            fontWeight: "bold",
          }}
        >
          {name}
        </div>
        {price && (
          <>
            <div
              css={{
                fontSize: "1.2rem",
                marginTop: "0.8rem",
              }}
            >
              {`${price[0]} `}
              <span
                css={{
                  color: "#3F3D56",
                  fontSize: "1rem",
                  fontWeight: "normal",
                }}
              >
                {price[1]}
              </span>
            </div>
            <div
              css={{
                color: "#3F3D56",
                fontSize: "1rem",
                fontWeight: "normal",
              }}
            >
              {price[2]}
            </div>
          </>
        )}
      </div>
      <div
        css={{
          fontSize: "1rem",
        }}
      >
        {description}
      </div>
      {topFeatures && <div css={{ marginTop: "1rem" }}>{topFeatures}</div>}
      {ctaComponent}
    </div>
    {children && <div>{children}</div>}
  </div>
);

export default PlanCard;
export { PlanFeature };
