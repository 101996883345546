/** @jsx jsx */

import "normalize.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { jsx } from "@emotion/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faInfoCircle,
  faLanguage,
  faLock,
  faStopwatch,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Settings as LuxonSettings } from "luxon";
import { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import Modal from "react-modal";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import API from "./API";
import AuthRoute from "./components/AuthRoute";
import Header from "./components/Header";
import { AuthContext } from "./context/auth";
import messagesEn from "./locales/compiled/en.json";
import messagesFr from "./locales/compiled/fr.json";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Onboarding from "./pages/onboarding";
import { fonts } from "./styles";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

library.add(
  faCheck,
  faStopwatch,
  faVoicemail,
  faLock,
  faInfoCircle,
  faLanguage
);

LuxonSettings.defaultLocale = "fr";

Modal.setAppElement("#root");

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [locale, setLocale] = useState(localStorage.getItem("locale") || "fr");

  useEffect(() => {
    localStorage.setItem("locale", locale);
  }, [locale]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      API.tokenPromise = Promise.resolve(token);
    }

    API.do.interceptors.request.use(async (config) => {
      Bugsnag.leaveBreadcrumb(
        `[API] ${config.method} ${config.url}`,
        null,
        "request"
      );

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${await API.tokenPromise}`,
        },
      };
    });

    API.do.interceptors.response.use(
      (res) => {
        if (process.env.NODE_ENV !== "production")
          // eslint-disable-next-line no-console
          console.log(
            `[API] ${res.config.method} ${res.config.url} | Response:`,
            res.data
          );

        return res;
      },
      async (error) => {
        // eslint-disable-next-line no-unused-expressions
        axios.isCancel(error)
          ? Bugsnag.leaveBreadcrumb("[API] Request canceled.", null, "request")
          : Bugsnag.leaveBreadcrumb(
              "[API] Error",
              {
                method: error.config?.method,
                payload: error.response || error.request,
                url: error.config?.url,
              },
              "request"
            );

        if (error.response?.data?.message === "Expired token.") {
          if (!API.refreshingToken) API.refreshingToken = API.refreshToken();
          await API.refreshingToken;
          return API.do.request(error.config);
        }

        if (
          error.response?.data.message === "Invalid token." &&
          error.request?.url !== "/logout"
        ) {
          setAuthenticated(false);
        }
        return Promise.reject(error);
      }
    );

    setAuthenticated(!!token);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      <IntlProvider
        locale={locale}
        messages={locale === "fr" ? messagesFr : messagesEn}
        defaultLocale="fr"
      >
        <Router>
          <div
            css={{
              ...fonts.sans,
              display: "flex",
              flexDirection: "column",
              minHeight: ["100vh", "-webkit-fill-available"],
              overflow: "hidden",
              width: "100vw",
            }}
          >
            <Header useLocale={[locale, setLocale]} />
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route path={["/login", "/signup"]} component={Login} />
              <AuthRoute path="/onboarding" component={Onboarding} />
              <AuthRoute path="/dashboard" component={Dashboard} />
            </Switch>
          </div>
        </Router>
      </IntlProvider>
    </AuthContext.Provider>
  );
};

export default App;
