/** @jsx jsx */

import { jsx } from "@emotion/core";

import { fonts } from "../styles";

const PageTitle = ({ title, caption }) => (
  <div css={{ marginBottom: "2rem" }}>
    <div
      css={{
        ...fonts.serif,
        fontSize: "1.8rem",
        fontWeight: "bold",
      }}
    >
      {title}
    </div>
    {caption && <div css={{ marginTop: "1rem" }}>{caption}</div>}
  </div>
);

export default PageTitle;
