import styled from "@emotion/styled";

import { fonts } from "../../styles";
import { TextInput as _TextInput } from "../Form";

const H1 = styled.h1({
  ...fonts.serif,
  fontSize: "1.8rem",
  margin: 0,
  marginBottom: "2rem",
});

const TextInput = styled(_TextInput)({ marginBottom: "1rem" });

export { H1, TextInput };
